<template>
  <div class="about">
    <h1>This is an about page</h1>
    <h1>Your account is {{ address }} and currently connected to {{ network_id }} </h1>
    <div>{{ warning }} </div>
    <input v-model="token_address" v-on:input="warning = ''" placeholder="Token address...">
    <button v-on:click="isHoneyPot">isHoneyPot?</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ethers } from 'ethers'

export default {
  name: 'About',
  data() {
    return {
      token_address: '',
      warning: ''
    }
  },
  computed: {
    ...mapGetters('ethereum', {
      provider: 'provider',
      address: 'address',
      signer: 'signer',
      balance: 'balance',
      network_id: 'network_id',
    })
  },
  watch: {
    token_address (address) {
      try {
        if (!address || address == '') {
          this.warning = ''
          return
        }
        const is_address = ethers.utils.getAddress(address)
        if (is_address) this.warning = ''
      } catch (err) {
        this.warning = 'Please input a valid token address'
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('ethereum/connect', false)
    await this.$store.dispatch('contract/init')
  },
  methods: {
    async isHoneyPot () {
      try {
        const token_address = this.token_address
        if (!token_address || token_address == '') {
          this.warning = 'please input token address first!'
          return
        }

        const is_honey_pot = await this.$store.dispatch('contract/isHoneyPot', token_address)
        console.log('is ohoney about', is_honey_pot)
      } catch (err) {
        console.log('err abot', err)
        this.warning = err.message
      }
    }
  }
}
</script>
